#slides {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 36rem;
    height: var(--slides-height);
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.8);

    & input {
        cursor: pointer;
        position: relative;
        display: none;
        opacity: 0;
    }

    & label {
        display: inline-block;
        width: var(--bullets-size);
        height: var(--bullets-size);
        border: calc(var(--bullets-size) / 5) solid var(--bullets-color-main);
        border-radius: 999px;
        background-color: transparent;
        margin-top: calc(var(--slides-height) - var(--bullets-margin) - 1rem);
        margin-right: var(--bullets-margin);
        z-index: 2;
        cursor: pointer;
        transition-duration: .4s;
        float: right;
        position: relative;
    }

    & input:checked + label {
        background-color: var(--bullets-color-accent);
    }

    & input:focus + label {
        box-shadow: 0 0 0 2px var(--bullets-color-accent), 0 0 5px var(--bullets-color-main);
    }

    & h3 {
        padding-bottom: 0.2em;
    }

    & h3 a {
        text-decoration: none;
    }

    & .story-item {
        margin: 0 !important;
        box-sizing: border-box;
    }

}


.slide {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;

    & .story-summary {
        /*noinspection CssUnknownProperty*/
        -webkit-line-clamp: var(--max-lines);
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
    }
}

input:checked ~ .slide {
    /* Add some transition too?.. */
    transform: translateX(100%);
}

input:checked + label + .slide {
    transition: opacity 0.5s ease-in-out;
    transform: translateX(0);
    opacity: 1;
}


