.scon {
    background-repeat: no-repeat;
    background-position: center;

    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;

    &.small {
        width: 1rem;
        height: 1rem;
    }

    &.inactive {
        filter: saturate(0) brightness(85%) drop-shadow(0 0 .05em rgba(0, 0, 0, .4));
    }

    &.disabled {
        opacity: 0.2;
        cursor: default;

        &.active {
            opacity: 0.7;
        }
    }
}

a.scon {
    &:not(.disabled) {
        cursor: pointer;
    }

    &, &.active, &.inactive:hover {
        filter: drop-shadow(0 0 .05em rgba(0, 0, 0, .4));
    }

    &:hover:not(.disabled) {
        filter: saturate(105%) brightness(110%) drop-shadow(0 0 .05em rgba(0, 0, 0, .2));;
    }
}

.icon-group {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    &.disabled {
        opacity: 0.2;
        cursor: default;
    }
}

.icon-metagroup {
    float: right;
    display: inline-flex;
    gap: 1.25rem;

    &.small .scon {
        width: 1rem;
        height: 1rem;
    }
}

.story-pinned {
    background-image: url(../images/assets/pin.svg);
    -svg-mixer-fill: rgba(0, 0, 0, 0.15);
}

.favorite {
    background-image: url(../images/assets/favorite.svg);
    -svg-mixer-fill: var(--color-yellow);
}

.bookmark {
    background-image: url(../images/assets/bookmark.svg);
    -svg-mixer-fill: var(--color-green);
}

.html {
    background-image: url(../images/assets/html.svg);
    -svg-mixer-fill: var(--color-blue);
}

.fb2 {
    background-image: url(../images/assets/fb2.svg);
    -svg-mixer-fill: var(--color-blue);
}

.abuse {
    background-image: url(../images/assets/abuse.svg);
    -svg-mixer-fill: var(--color-red);
}

.vote-up {
    background-image: url(../images/assets/upvote.svg);
    -svg-mixer-fill: var(--color-green);
}

.up {
    background-image: url(../images/assets/upvote.svg);
    -svg-mixer-fill: var(--formatting-color);
}

.vote-down {
    background-image: url(../images/assets/downvote.svg);
    -svg-mixer-fill: var(--color-red);
}

.down {
    background-image: url(../images/assets/downvote.svg);
    -svg-mixer-fill: var(--formatting-color);
}

.formatting {
    background-image: url(../images/assets/formatting.svg);
    -svg-mixer-fill: var(--formatting-color);
}

.entity-abuse {
    background-image: url(../images/assets/abuse.svg);
    -svg-mixer-fill: var(--color-red);
}

.entity-edit {
    background-image: url(../images/assets/edit.svg);
    -svg-mixer-fill: var(--color-yellow);
}

.entity-delete {
    background-image: url(../images/assets/delete.svg);
    -svg-mixer-fill: var(--color-red);
}

.entity-restore {
    background-image: url(../images/assets/restore.svg);
    -svg-mixer-fill: var(--color-green);
}

.entity-draft {
    background-image: url(../images/assets/draft.svg);
    -svg-mixer-fill: var(--color-yellow);
}

.entity-approve {
    background-image: url(../images/assets/approve.svg);
    -svg-mixer-fill: var(--color-violet);
}

.entity-revoke {
    background-image: url(../images/assets/revoke.svg);
    -svg-mixer-fill: var(--color-violet);
}

.entity-publish {
    background-image: url(../images/assets/publish.svg);
    -svg-mixer-fill: var(--color-green);
}

.entity-pin {
    background-image: url(../images/assets/pin.svg);
    -svg-mixer-fill: var(--color-violet);
}

.entity-journal {
    background-image: url(../images/assets/journal.svg);
    -svg-mixer-fill: var(--color-blue);
}
