@define-mixin box-shadow {
    box-shadow: .1em .1em .2em rgba(0, 0, 0, 0.4);
}

/* Body, base and containers */
body {
    background-color: #dde;
    word-wrap: break-word;
    text-size-adjust: 100%; /* for iPhone */
}

a {
    text-decoration-skip-ink: none;
}

.container {
    padding: 0 20px;
    background-color: #F6F6F6;
    box-shadow: 0 0 10px -5px #000000;
    margin-bottom: 20px;
    border-radius: 0 0 8px 8px;
    width: 960px;
}

/* Header */
.page-header,.logopic,.footer,.nav-pills {
    width: 100%;
    padding: 0 20px;
    margin-left: -20px;
}

.page-header {
    display: flex;
    align-items: center;
    background: #1D2A4F no-repeat 0 0;
    height: 80px;
    font-family: Georgia, Times, serif;
}

.logopic {
    height: calc(var(--slides-height) + var(--slides-border) * 2);
    position: relative;
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.logopic-sourcelink {
    position: absolute;
    left: 8px;
    top: 8px;
    padding: 1px 6px;
    font-size: 12px;
    background-color: #333;
    background-color: rgba(50, 50, 50, 0.6);
    color: white;
    text-decoration: underline;
    display: inline-block;

    opacity: 0;
    pointer-events: none;
    transition-property: opacity;
    transition-duration: 500ms;
}

.logopic-sourcelink:hover {
    color: #cdcae9;
}

.logopic:hover .logopic-sourcelink {
    opacity: 1;
    pointer-events: auto;
}

/* Footer */

.footer {
    min-height: 30px;
    padding: 10px 20px;
    background: #1D2A4F no-repeat 0 0;
    color: #FAFAE8;
    border-radius: 0 0 8px 8px;
    /* Flexin' like a pro */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#footer-links {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    & > li {
        flex-grow: 1;
    }
}

@media (max-width: 660px) {
    .footer {
        min-height: 0;
        flex-flow: column-reverse;
        gap: 0.25em;

        & > .copyright {
            font-size: small;
        }
    }
}

.footer a {
    color: inherit;
    text-decoration: underline;
}


/* NAVIGATION */

/* Common styles for header and footer nav */

ul.nav-items {
    list-style: none outside none;
    margin: 0;
}

ul.nav-items-right {
    float: right;
}

ul.nav-items > li {
    display: inline-block;
}

ul.nav-items > li.left {
    float: left;
}

ul.nav-items > li.right {
    float: right;
}

ul.nav-items > li > a {
    display: inline-block;
    line-height: 14px;
    padding: 8px 12px;
    color: #FAFAE8;
}

ul.nav-items > li.active a,
ul.nav-items > li:hover a {
    background-color: #FAFAE8;
    border-radius: 5px;
    color: #1D2A4F;
}

ul.nav-items > li.active a {
    text-decoration: none;
}


/* Header nav */

.nav-main {
    background: #1D2A4F no-repeat 0 0;
    margin-bottom: 0;
    font-size: 16px;
    overflow: visible;
    min-height: 40px;

    /* bootstrap sucks */
    margin-left: -20px;
    margin-right: -20px;
}

ul.nav-main-links {
    float: left;
    padding: 5px 20px 5px 20px;
    box-sizing: border-box;
}

.nav-main-profile {
    float: right;
    text-align: right;
    box-sizing: border-box;
    margin: 5px 0;
}

.nav-main-profile > ul.nav-items {
    margin: 0 20px;
}


.nav-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 5px;
    cursor: pointer;
    background: none center center no-repeat;
    background-size: 16px 16px;
}

.nav-icon:hover, .nav-icon.active {
    background-color: #FAFAE8;
    border-radius: 5px;
}

.nav-icon-add {
    background-image: url(../images/icon-add.png);
    float: left;
}

.nav-icon-add:hover, .nav-icon-add.active {
    background-image: url(../images/icon-add-active.png);
}

/* Notification button */
.nav-icon-bell {
    background-image: url(../images/icon-bell.png);
    float: left;
    position: relative;
}
.nav-icon-bell:hover, .nav-icon-bell.active {
    background-image: url(../images/icon-bell-active.png);
}

.nav-icon-bell > .new-count {
    position: absolute;
    top: 10%;
    right: 10%;
    display: block;
    background-color: #d00;
    border-radius: 50%;
    font-size: 10px;
    color: white;
    line-height: 1em;
    min-width: 1em;
    min-height: 1em;
    text-align: center;
    padding: 2px;
    pointer-events: none;
}

.nav-icon-bell-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.bell-popup {
    position: absolute;
    right: 0;
    background-color: #F6F6F6;
    top: 35px;
    width: 370px;
    overflow: visible;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    z-index: 15;
}

.bell-popup-content {
    min-height: 3em;
    max-height: 25em;
    overflow: auto;
    cursor: auto;
    text-align: left;
    box-sizing: border-box;
}

.bell-popup-content-loading {
    width: 128px;
    height: 128px;
    background-position: center!important;
    margin: auto;
}

.bell-popup:before {
    position: absolute;
    bottom: 100%;
    right: 9px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: #F6F6F6;
    content: '';
    pointer-events: none;
}

.mobile-menu-btn {
    display: none;
    float: left;
    background-image: url(../images/mobile-menu.png);
}

.mobile-menu-btn:hover, .mobile-menu-btn.active {
    background-image: url(../images/mobile-menu-active.png);
}

.nav-profile-menu {
    position: relative;
    display: inline-block;
}

.nav-profile-menu-header {
    line-height: 30px;
    color: white;
    cursor: pointer;
}

.nav-profile-menu-text {
    display: inline-block;
    padding: 0 10px;
    vertical-align: top;
}

.nav-profile-menu-text:before {
    display: inline-block;
    content: '';
    width: 12px;
    height: 12px;
    background: url(../images/menu-arrow.png) center center no-repeat;
    background-size: 12px 12px;
    vertical-align: middle;
    margin-right: 5px;
}

.nav-profile-menu-header:hover .nav-profile-menu-text {
    background-color: #FAFAE8;
    color: #1D2A4F;
    border-radius: 5px;
}

.nav-profile-menu-header:hover .nav-profile-menu-text:before {
    background: url(../images/menu-arrow-active.png) center center no-repeat;
    background-size: 12px 12px;
}

.user-avatar-container {
    width: 2em;
    height: 2em;
    border-radius: 0.2em;
    margin: 0 0.25em;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}

.user-avatar-placeholder {
    background: url(../images/userpic.png) no-repeat center / contain;
}

.user-avatar-container img {
    width: 100%;
    border-radius: inherit;
}

ul.nav-profile-menu-content {
    position: absolute;
    background-color: #1D2A4F;
    list-style: none;
    padding: 0;
    min-width: 100%;
    text-align: left;
    right: 0;
    z-index: 11;
    white-space: nowrap;
    display: none;
}

ul.nav-profile-menu-content.shown {
    display: block;
}

ul.nav-profile-menu-content > li {
    margin: 0;
    display: block;
    line-height: 30px;
    padding: 5px 20px;
}

ul.nav-profile-menu-content > li > a {
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 640px) {
    ul.nav-main-links {
        float: none;
        clear: both;
        display: none;
    }

    ul.nav-main-links.shown {
        display: block;
    }

    ul.nav-main-links.nav-items > li {
        display: block;
    }

    .mobile-menu-btn {
        display: inline-block;
        margin: 5px;
    }
    .nav-icon {
        width: calc(30px);
    }
    .nav-main-profile {
        width: calc(100% - 40px);
    }
    .nav-profile-menu {
        width: calc(100% - 80px);
        min-width: auto;
    }
}

/* Login form */
.login-form {
    margin: 2px 0 0;
}

.login-form input {
    border-color: transparent;
}

.login-form .input-small {
    width: 110px;
}

.login-form a {
    font-size: 11px;
    line-height: 12px;
    margin-left: 6px;
    display: inline-block;
    vertical-align: middle;
}
/* End Header, Menus, Login Form */

/* Headings and stuff inside them */
h1 {
    font-size: 32px;
    line-height: 42px;
    margin: 6px 0 6px;
}

h1 sup {
    font-size: 17px;
}

h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 6px;
}

h2 sup {
    font-size: 14px;
}

h3 {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 5px;
}

h3 sup {
    font-size: 12px;
    color: gray;
}

h4 {
    font-size: 17px;
    line-height: 20px;
    margin: 0 0 5px;
}

/* Index-specific stuff */
#content > div:first-child {
    margin-top: 1em;
}

/* Story item */
.story-item {
    padding: 10px 12px 6px;
    margin: 0 0 10px -12px;
}

#content .story-item:nth-child(2n+1), .comment-item:nth-child(2n+1), .comment-tree-loader:nth-child(2n+1) {
    background-color: #fefefe;
}

.notification .comment-item:nth-child(2n+1) {
    background-color: transparent;
    padding: 0;
}

.sidelist.top-list .story-item {
    padding: 8px 12px 10px;
    margin-bottom: 6px;
}

.story-item h3 {

}

.story-item h4 {

}

.sidelist .story-item h3 {
    font-size: 16px;
    line-height: 18px;
}

.story-item p {
    font-size: 12px;
    margin-bottom: 4px;
}

.sidelist .story-item h3 {
    margin: 0 0 3px;
}

.sidelist .story-item p {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 0;
    color: #999;
}

.chapter-viewed-icon {
    display: inline-block;
    vertical-align: baseline;
}

.chapter-viewed-icon:before {
    display: inline-block;
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(../images/chapter-viewed.png);
    background-size: 12px 12px;
}

a.authorlink,a.edit-link {
    color: #999;
}

a.authorlink:hover,a.edit-link:hover {
    color: #ff3368;
}

.author-profile-links {
    margin: 0 0 13px 0;
    padding: 0;
    list-style: none;
}

.author-profile-links > li {
    line-height: 28px;
    margin: 0;
    padding: 0;
}

.author-profile-links > li.active > a {
    color: inherit;
    text-decoration: none;
}

.story-item p.meta {
    font-size: 12px;
    color: #999;
}

.character-list {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;

    & a {
        text-decoration: none;
    }

    & img {
        border-radius: .25em;
        @mixin box-shadow;
    }
}

.more {
    margin: 0 0 30px;
    font-size: 12px;
}

.story-control-btn {
    margin-top: 6px;
    float: right;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    font-weight: normal;
}

.story-control-btn a {
    text-decoration: none;
}

.story-control-btn .story-approve-btn.loading,
.story-control-btn .story-publish-btn.loading,
.publish-chapter-btn.loading {
    opacity: 0.5;
    cursor: default;
}

/* Vote-buttons */

.vote-area-1 {
    display: inline;
}

.vote-area-2 {
    display: block;
    margin: 10px 0;
}

.starblock {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    line-height: 24px;
    overflow: hidden;
}

.starblock-voting {
    vertical-align: baseline;
    margin: 0;
}

.starblock-voting.uploading {
    opacity: 0.5;
}

.starvote {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: transparent url(../images/votes.png) no-repeat 0 0;
    background-size: 100%;
}

.starvote.star-button {
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
}

.starblock-voting .starvote.star-button {
    float: right;
}

.starvote.star-0 {
    background-position: 0 0;
}

.starvote.star-1 {
    background-position: 0 -24px;
}

.starvote.star-2 {
    background-position: 0 -48px;
}

.starvote.star-3 {
    background-position: 0 -72px;
}

.starvote.star-4 {
    background-position: 0 -96px;
}

.starvote.star-5 {
    background-position: 0 -120px;
}

.starvote.star-6 {
    background-position: 0 -144px;
}

.starblock-voting:hover .starvote.star-button {
    background-position: 0 0;
}

.starblock-voting .starvote.star-button:hover,
.starblock-voting .starvote.star-button:hover ~ .starvote.star-button {
    background-position: 0 -120px;
}

h1 .alert {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    padding-right: 14px;
    margin: 0;
    position: relative;
    top: 4px;
    vertical-align: top;
}

/* Thumbnails */
.thumbnail,.characters-select img {
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    border: 1px solid #eee;
    padding: 4px;
    background: rgba(255, 255, 255, 0.7)
}

.characters-select img {
    margin-bottom: 4px;
}

.characters-group {
    display: inline-block;
    padding-right: 23px;
}

/* Story tags */

/* Genra */
.story-tag {
    padding: 4px 9px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    line-height: 16px;
    background-color: var(--default-tag-color);
    @mixin box-shadow;

    /* override bootstrap styles for <label> */
    font-size: inherit;
    font-weight: inherit;

    &.blacklisted-tag {
        text-decoration: line-through;
        opacity: 0.5;
    }
    &:hover {
        color: #fff;
    }
}

.story-item .meta .story-tag {
    padding: 2px 7px;
}

.secondary-tag, .spoiler-tag {
    display: none;
}

input.show-tags-trigger {
    display: none;

    & + label {
        display: inline-block;
        margin: 0;
    }
    & + label ~ label {
        display: none;
    }

    &:checked + label {
        display: none;
    }

    &:checked + label ~ label {
        display: inline-block;
        margin: 0;
    }

    &:checked + label ~ input:checked + label {
        display: none;
    }

    &:checked + label ~ .secondary-tag {
        display: inline-block;
    }
}

input.show-spoiler-trigger {
    display: none;

    & + label {
        margin: 0;
    }

    &:checked + label {
        display: none;
    }

    &:checked + label ~ .spoiler-tag {
        display: inline-block;
        opacity: 0.5;
    }
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
}

/* Tags list page */
.all-categories-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.all-categories-list > li {
    margin: 0 0 24px 0;
    padding: 0;
}

.all-tags-list {
    list-style: none;
}

.all-tags-list > li {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 16px 0;
    padding: 0 16px 0 0;
    box-sizing: border-box;
}

/* Tag page */

.tag-page {
    margin-bottom: 8px;
}

.tag-page .tag-stories-count {
    font-style: italic;
}

.tag-page .tag-aliases {
    font-style: italic;
}

.tag-page .tag-description {
    margin-top: 1em;
}

/* Story page */
.story-page {
    margin-bottom: 60px;
}

.story-item-header,
.story-info-header {
  font-family: 'Trebuchet MS', Arial, sans-serif;
  font-weight: 400;
  text-rendering: optimizelegibility;
}

.story-item-header {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
}

.story-info-header {
    font-size: 32px;
    line-height: 42px;
    margin: 6px 0;
}

.story-title {
    display: inline;
    margin: 0;
}

.green {
    color: green;
}

.story-mark {
    white-space: nowrap;
}

.story-mark.complete {
    color: green;
}

.story-mark.on-moderation {
    color: #ff7700;
}

.story-mark.incomplete,
.story-mark.draft {
    color: #f2c200;
}

.story-mark.suspended {
    color: red;
}

.story-mark.translation {
    color: #999;
}

.story-item-header .story-mark {
    font-size: 12px;
}

.story-info-header .story-mark {
    font-size: 17px;
}

.story-info img,
.story-notes img {
    max-width: 100%;
}

.story-chapters {
    margin-top: 12px;
}

.more-info p, .more-info form {
    font-size: 12px;
    font-weight: 300;
}

.story-chapters-list {
    list-style-type: none;
    margin-left: 0;
    margin-top: 6px;
}

.story-chapters-list li {
    padding: 0 0 6px;
    font-size: 12px;
}

.story-chapters-list h4 {
    margin-bottom: 0;
}

.chapter-text-block {
    width: 70%;
    margin: 0 auto;
}

.chapter-text {
    font-family: Verdana, Arial, sans-serif;
    font-size: 14px;
    line-height: 26px;
    hyphens: none;
}

.chapter-text img {
    max-width: 100%;
}

.chapter-text p,.chapter-text span {
    margin-bottom: 20px;
}

.chapter-text.mode-justify {
    text-align: justify;
    text-align-last: left;
}

.chapter-text.mode-hyphens {
    hyphens: manual;
}

.chapter-text.mode-indent-left p, .chapter-text.mode-indent-left span {
    margin-bottom: 0;
    text-indent: 2em;
}

.chapter-text.mode-indent-both p, .chapter-text.mode-indent-both span {
    text-indent: 2em;
}

.chapter-text p[align="center"],
.chapter-text h3[align="center"],
.chapter-text span[align="center"] {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.chapter-text p[align="left"],
.chapter-text h3[align="left"],
.chapter-text span[align="left"] {
    text-align: left;
}

.chapter-text p[align="right"],
.chapter-text h3[align="right"],
.chapter-text span[align="right"] {
    display: inline-block;
    width: 100%;
    text-align: right;
}

.chapter-text.mode-indent-left p[align="center"],
.chapter-text.mode-indent-left h3[align="center"],
.chapter-text.mode-indent-left span[align="center"] {
    text-indent: 0;
}

.chapter-text.mode-justify p[align="center"],
.chapter-text.mode-justify h3[align="center"],
.chapter-text.mode-justify span[align="center"] {
    display: inline-block;
    width: 100%;
    text-align: center;
    text-align-last: center;
}

.chapter-text.mode-justify p[align="left"],
.chapter-text.mode-justify h3[align="left"],
.chapter-text.mode-justify span[align="left"] {
    text-align: justify;
    text-align-last: left;
}

.chapter-text.mode-justify p[align="right"],
.chapter-text.mode-justify h3[align="right"],
.chapter-text.mode-justify span[align="right"] {
    display: inline-block;
    width: 100%;
    text-align: justify;
    text-align-last: right;
}

/* Режимы просмотра текста */
.chapter-text.serif-font {
    font-family: Georgia, Utopia, Palatino, 'Palatino Linotype', serif;
    /* 'Times New Roman', Times, serif */
    font-size: 16px;
    line-height: 28px;
}

.chapter-text.mono-font {
    font-family: 'Courier New', Courier, monospace;
    font-size: 15px;
}

.chapter-text.small-font {
    font-size: 12px;
}

.chapter-text.big-font {
    font-size: 17px;
}

.chapter-text.serif-font.big-font {
    font-size: 21px;
}

.chapter-text-block.serif-font.big-font {
    width: 90%;
}

.chapter-text.serif-font.small-font {
    font-size: 13px;
}

.chapter-text.mono-font.big-font {
    font-size: 21px;
}

.chapter-text-block.mono-font.big-font {
    width: 90%;
}

.chapter-text.mono-font.small-font {
    font-size: 13px;
    line-height: 22px
}

.chapter-text.serif-font p {

}

.chapter-text.mono-font p {

}

.chapter-text.serif-font.small-font p {

}

.chapter-text.mono-font.small-font p {

}

.footnote-back-links a {
    text-decoration: none;
}

.chapter-text > hr {
    border: none !important;
    font-size: larger;
    font-weight: bold;
    color: #333;
    text-align: center;
    text-align-last: center;
}

.chapter-text > hr::after {
    content: "* * *";
}

hr {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #ddd;
}

.chapter-text .footnote:first-of-type {
    margin-top: 1rem;
    border-top: 2px groove #ddd;
    padding-top: 1rem;
}

.chapter-navigation {
    text-align: center;
}

.spoiler-gray {
    background-color: #ccc;
    color: #ccc;
}

.spoiler-gray a,
.spoiler-gray a:visited,
.spoiler-gray {
    color: #ccc;
}

.spoiler-gray:hover {
    background-color: transparent;
    color: #666;
}

.spoiler-gray:hover a {
    background-color: transparent;
    color: #09f;
}

.spoiler-gray img {
    filter: contrast(0%) brightness(159.375%);
}

.spoiler-gray:hover img {
    filter: inherit;
}

/* Storypanel */
.story-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-left: -12px;
    margin-right: -12px;
    background-color: #fff;
    padding: .75em 1em;
    border-radius: .5em;
    font-size: 12px;
    box-sizing: border-box;
    z-index: 10;
    transition-property: opacity;
    transition-duration: 200ms;

    & > div {
        display: flex;
        align-items: center;
        gap: 2em;
    }

    & .up {
        transition: opacity;
        transition-duration: 250ms;
        opacity: 0;
        pointer-events: none;
    }
    &.story-panel-floating .up {
        opacity: 1;
        pointer-events: auto;
    }

    & .chapter-switch {
        display: flex;
        gap: 2em;
    }
}

.story-panel-sticky {
    position: sticky;
    top: 0;
}

.story-panel.no-js .js-required {
    display: none;
}

.story-panel.story-panel-floating {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    opacity: 0;
}

.story-panel.story-panel-floating:hover {
    opacity: 1;
}

.story-panel select {
    height: auto;
    font-size: 11px;
    margin-bottom: 0;
    padding: 2px;
    vertical-align: middle;
}

select.select-font {
    width: 110px;
}

select.select-size {
    width: 90px;
}

.story-formatting-window {
    position: fixed;
    z-index: 15;
    width: 300px;
    top: 36px;
    max-height: calc(100% - 37px);
    overflow-y: auto;
    left: calc(50% - 150px);
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 18px;

    opacity: 1.0;
    transform: translateY(0);
    transition-property: opacity, transform;
    transition-duration: 150ms;
}

.story-formatting-window.hiding {
    transform: translateY(-20px);
    opacity: 0.0;
}

.story-formatting-window.hidden {
    display: none;
}

.story-formatting-window-title {
    border-bottom: 1px solid #eee;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 12px;
}

.story-formatting-window-title .close {
    float: right;
    vertical-align: middle;
}

.story-formatting-window-content {
    padding: 0 12px 12px 12px;
}

.formatting-reset-btn-wrap {
    text-align: right;
    margin: 0 -5px;
}

.formatting-reset-btn {
    padding-left: 4px;
    padding-right: 4px;
}

.story-formatting-window label {
    margin: 4px 0 0 0;
    padding: 0;
    display: block;
    font-size: inherit;
    line-height: inherit;
    cursor: auto;
}

.story-formatting-window select {
    margin: 0 -5px 4px -5px;
    padding: 5px;
    width: calc(100% + 10px);
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    height: auto;
}

.story-item .icon-group {
    float: right;
}

.smaller {
    background: transparent url(../images/smaller.png) no-repeat 0 0;
}

.bigger {
    background: transparent url(../images/bigger.png) no-repeat 0 0;
}

.alert-mini {
    padding: 3px;
}

/* Series page */
.series-item {
    margin-bottom: 30px;
}

.series-item .story-item {
    margin-left: 8px;
}

.series-item h2 {
    font-size: 26px;
}

.series-block {
    padding-bottom: 20px;
}

.series-block h2 button {
    vertical-align: top;
    margin-top: 3px;
}

.series-block .story-item ul li {
    margin: 6px 0;
}

.series-block .story-item ul li sup .green {
    font-size: 13px;
}

/* Author and contributing stories tabs */

.tab-author-stories-input,
.tab-contributing-stories-input {
    display: none;
}

.tab-author-stories-input + label,
.tab-contributing-stories-input + label {
    display: inline-block;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 6px;
    color: #0086E5;
    border-bottom: 1px dotted #0086E5;
    cursor: pointer;
}

.tab-author-stories-input + label:hover,
.tab-contributing-stories-input + label:hover {
    color: #ff3368;
    border-bottom: 1px dotted #ff3368;
}

.tab-author-stories-input:checked + label,
.tab-contributing-stories-input:checked + label,
.tab-author-stories-input:checked + label:hover,
.tab-contributing-stories-input:checked + label:hover {
    color: inherit;
    border-bottom: 1px dotted transparent;
}

.tab-author-stories,
.tab-contributing-stories {
    display: none;
}

.tab-author-stories-input:checked ~ .tab-author-stories,
.tab-contributing-stories-input:checked ~ .tab-contributing-stories {
    display: block;
}

/* Search page and results */
.search-input {
    width: 384px;
    margin-right: 20px;
}

.search-type-buttons {
    padding-left: 0;
}

.search-tags-mode-widget,
.search-characters-mode-widget {
    list-style: none;
    margin: 0;
    padding: 0 0 16px 10px;
}

.search-tags-mode-widget > li,
.search-characters-mode-widget > li {
    display: inline-block;
    margin-right: 10px;
}

.search-tags-mode-widget input,
.search-characters-mode-widget input {
    margin: 0;
}

.search-tags-mode-widget label,
.search-characters-mode-widget label {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}

.search-results {
    margin-bottom: 100px;
    overflow: visible;
}

#searchTabNav li {
    display: inline-block;
}

.search-page div.collapse {

}

.search-page div.collapse h3 {
    margin-bottom: 8px;
    margin-top: 12px;
}

.padded.medium-input {
    padding-left: 20px;
}

.padded.medium-input > input {
  margin: 0 8px;
  width: 100px;
}
/* === Editing, Forms, Active elements, Buttons === */

/* Forms and stuff */

.input-xxxlarge {
    width: 800px;
}

.checkbox.inline+.checkbox.inline {
    margin-left: 0;
}

.checkbox.inline {
    margin-right: 10px;
}

div.padded {
    margin: 0 0 10px 0;
}

.form-sumbit {
    margin: 12px 0;
}

.events label.checkbox {
    width: 260px;
}

input.hidden {
    display: none;
}

.controls .help-inline {

}

.controls.radio .help-inline {
    vertical-align: middle;
}

.controls textarea {

}

.buttons-data {
    display: inline-block;
}

.controls.radio {
    padding-left: 0;
}

.form-actions {
    padding-left: 0;
    background-color: transparent;
}

.nav-pills.nav-simple ul {
    margin-bottom: 0;
}

.nav-pills.nav-simple>li>a {
    text-decoration: none;
}

.nav-pills.nav-simple>li>a:hover {
    color: #0088cc;
}
.nav-pills.nav-simple>li.active>a:hover {
    color: #ffffff;
}
.bootstrap > label {
  font-size: 16px;
  margin-bottom: 10px;
}
blockquote {
    margin: 5px 0;
}
blockquote > p {
    line-height: inherit;
    font-weight: inherit;
}

/* Registration form */
.registration-form {

}

.reg-complete {
    padding-bottom: 200px;
}

.help-inline,.help-block {
    font-style: italic;
    color: #999;
}

.controls .help-block {
    padding: 5px 0;
}

/* Buttons and stuff */
.btn-collapse {
    border-style: dashed;
    margin-bottom: 6px;
}

.story-page .collapse {
    background-color: #fff;
}

.collapse p {
    padding: 8px 0 8px 10px;
    line-height: 22px;
    margin: 0;
}

a.invisible-link,
a.invisible-link:visited {
    color: inherit;
    text-decoration: none;
}

a.invisible-link:hover {
    text-decoration: underline;
}

.story-page .story-genres {
    margin-bottom: 4px;
}

img.ui-selected {
    background-color: #66CC66;
    border-color: transparent;
}

/* Workaround for small resolutions */
.btn-group.btn-group-wrap {
    white-space: normal;
}

/* Add and edit story */
a.btn {
    text-decoration: none;
}

.chapters-control {
    margin: 0;
    list-style-type: none;
}

.chapters-control li.btn-large {
    display: block;
    text-align: left;
    font-size: 17px;
    margin: 5px 0;
    padding: 8px 12px 7px;
    width: 70%;
    background-image: none;
    background-color: #F5F5F5;
    overflow: hidden;
}

.chapters-control li.btn-large:hover {
    background-color: #F1F1F1;
}

.chapters-control li .btn-group {
    float: right;
}

.chapters-control li .btn-success {
    margin-top: 8px;
}

.btn-mini .caret {
    margin-top: 8px;
}

.chapter-textarea {
    width: 90%;
    height: 300px;
}

.notop {
    border-top: none;
    padding-top: 0;
}
/* Стиль для автодополнение */
.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    padding: 4px 0;
    margin: 2px 0 0 0;
    list-style: none;
    background-color: #ffffff;
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
}

.ui-menu-item>a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #555555;
    white-space: nowrap;
}

.ui-state-hover,.ui-state-active {
    color: #ffffff;
    text-decoration: none;
    background-color: #0088cc;
    border-radius: 0;
    background-image: none;
}

.story-item:nth-child(2n) .collapse {
    background-color: transparent;
}

.chapters-list {
    list-style-type: none;
    margin: 10px 0 10px 12px;
}

.collapse .chapters-list {
    margin-top: 4px;
}

.chapters-list li {
    font-size: 13px;
    padding: 4px 0;
}

.chapters-list .chapter-title {
    font-size: 15px;
}

.contact-links {
    display: flex;
    flex-direction: column;
    gap: .5em;
}

/* === Bottom of the page stuff === */
/* Comments */
.comments {
    margin-top: 60px;
}

.comment-item {
    padding: 8px 12px 8px;
    line-height: 22px;
    border-left: 0.2em solid #ddd;
}

.comment-item.comment-item-short {
    border: 0;
}

.comment-item.comment-new {
    border-left: 0.2em solid #9e9;
    background-color: rgba(180, 255, 180, 0.3);
}

.comment-item.comment-new:nth-child(2n+1) {
    background-color: rgba(192, 255, 192, 0.7);
}

.comment-item-notify-label {
    font-size: 11px;
    line-height: 12px;
    color: #888;
}

/* index page */
.sidelist .comment-item {
    margin-left: -12px;
    border-left: 0;
}

.vote-total {
    color: #888;
    font-weight: bold;
}

.voted-positive {
    color: var(--color-green);
}

.voted-negative {
    color: var(--color-red);
}

.comment-text, .comment-text p {
    font-size: 12px;
    margin-bottom: 4px;
}

.comment-text img {
    max-width: 100%;
}

.comment-text-deleted {
    color: #888;
    font-size: 0.8em;
    font-style: italic;
}

.comment-meta {
    font-size: 12px;
    color: #999;
    margin: 0 0 4px;
}

.comment-by-topic-author a {
    color: #78c;
}

.comment-story-link .link-inline {
    color: inherit;
}

.comments-tree {
    margin: 6px 0 6px -12px;
    line-height: 22px;
}

.comments-tree .comment-item {
    min-width: 30%;
    max-width: 70%;
    box-sizing: border-box;
}

.comment-tree-loader {
    width: 70%;
    box-sizing: border-box;
    padding-left: 0.5em;
    border-left: 0.2em dotted #ddd;
}

.comment-tree-loading {
    opacity: 0.3;
}

.authorlink {
    font-weight: bold;
}

.userlink-guest {
    font-weight: bold;
}

.comment-link, .comment-parent-link {
    text-decoration: none;
    display: inline-block;
    margin-left: 0.3em;
}

.comment-link:hover, .comment-parent-link:hover {
    text-decoration: underline;
}

.comment-answer-link {
    text-decoration: underline;
    text-decoration-style: dotted;
    display: inline-block;
    margin-left: 0.3em;
}

.comment-add-link {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 6px;
    text-decoration: underline;
    text-decoration-style: dotted;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    display: inline-block;
}

.comment-edited-icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: baseline;
    background-image: url(../images/edit.png);
    background-size: 8px 8px;
    background-repeat: no-repeat;
    background-position: center center;
}

.comment-form-content {
    background-color: white;
    width: 70%;
    padding: 8px 12px 8px;
    box-sizing: border-box;
}

/* Pagination */
#pagination, .pagination {
    margin: 10px 0;
}

#pagination .input-xlarge, .pagination .input-xlarge {
    margin-bottom: -1px;
    width: 50px;
    text-align: center;
}

.pagination-loading {
    opacity: 0.5;
}

.message-500 {
    margin-bottom: 300px;
}

.passres-form-text {
    margin-bottom: 160px;
}

.login-text {
    margin-bottom: 80px;
}

.login-text form,.passres-form form {
    margin-top: 20px;
}

.staticpage::after {
    /* clearfix for bootstrap */
    display: table;
    content: '';
    clear: both;
}

.staticpage-content {
    width: 70%;
}

.staticpage-content h2 {
    margin-top: 24px;
}

.staticpage-content li {
    margin-bottom: 8px;
}

.main-menu {
    margin: 0;
    color: #CDCAE9;
    list-style-type: none;
}

.main-menu li {
    display: inline-block;
    font-size: 22px;
    padding: 5px 20px 0 0;
}

.main-menu .logolink {
    font-size: 38px;
    padding: 0 23px 0 0;
    line-height: 1;
}

.main-menu .logolink a {
    color: #CDCAE9 !important;
    text-decoration: none;
}

.main-menu li a {
    color: #FAFAE8;
}

.main-menu li a:hover {
    color: #fff;
}

#menu {
    width: 100%;
    height: 40px;
    margin: 0 auto;
    font-size: 17px;
    background-color: #DEE2FC;
    clear: both;
    overflow: hidden;
}

#menu ul {
    float: left;
    padding: 0 0 0 1%;
}

#menu li {
    float: left;
    display: inline;
    color: #333;
    padding-right: 1%;
    padding-top: 1.5%;
}

/* ajax loading icon */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    width: 128px;
    height: 128px;
    pointer-events: none;
    transition: opacity 0.15s ease;
    transition-delay: 0.15s;
    z-index: 12;
}

.loader {
    background: url(../images/ajax-loader.gif) no-repeat;
}

.loader-hidden {
    opacity: 0;
    transition-delay: 0s;
}


/* editing contributors */

.controls-contributors-item {
    margin: 4px 0;
}

.controls-contributors-user {
    display: inline-block;
    min-width: 140px;
    text-align: right;
}

.controls-contributors-user > input {
    width: 126px;
}

.controls-contributors-access {
    display: inline-block;
}

.controls-contributors-access > select {
    width: auto;
}

.controls-contributors-visible {
    display: inline-block;
}

.controls-contributors-addlabel {
    width: 100px;
    margin-left: -100px;
    display: inline-block;
    text-align: right;
}


/* edit log */

ul.editlog {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}

.editlog-item {
    margin: 0;
    padding: 6px;
    line-height: 1.25;
}

ul.editlog .editlog-item:nth-child(2n+1) {
    background-color: #fefefe;
}

.editlog-date {
    font-size: 12px;
    color: #888;
}

.editlog-changed-items {
    margin-left: 28px;
}

.editlog-changes-link {
    font-size: 12px;
    margin-left: 24px;
}

.editlog-chapter-diff {
    word-break: normal;
    max-height: 20em;
    overflow-y: auto;
    background-color: white;
    line-height: 1.25;
}

.editlog-chapter-diff ins {
    text-decoration: none;
    background-color: #7f7;
}

.editlog-chapter-diff del {
    text-decoration: none;
    background-color: #f77;
}

.editlog-unchanged {
    color: #999;
}

.editlog-expand-btn-wrap {
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 12px;
    padding: 4px;
    line-height: 1.1;
    background-color: #eee;
    border-radius: 4px;
}

.editlog-expand-btn, .editlog-expand-btn:visited {
    color: #555;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1px dotted #555;
}

.editlog-contributors-table {
    overflow: hidden;
}

.editlog-contributors-table-old,
.editlog-contributors-table-new {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
}

.editlog-contributors-arrow {
    margin: 0 32px;
    float: left;
}

.editlog-contributors-invisible {
    opacity: 0.5;
}


/* news */

.newsitem {
    margin: 0 0 10px -12px;
    padding: 10px 12px 6px;
}

.news-list-short .newsitem {
    margin-bottom: 0;
}

.news-list .newsitem-listitem:nth-child(2n+1) {
    background-color: #fefefe;
}

.newsitem-title, .newsitem-title-link {
    font-size: 20px;
    font-family: 'Trebuchet MS', Arial, sans-serif;
}

.news-list-short .newsitem-title-link {
    font-size: 16px;
}

.newsitem-date {
    font-size: 10px;
    color: #555;
}


/* notifications */

.notification {
    font-size: 12px;
    line-height: 22px;
    padding: 8px 10px;
}

.notification:nth-child(2n+1) {
    background-color: #fefefe;
}

.notification-meta {
    color: #999;
    margin-bottom: 4px;
}

.notification-story-meta {
    margin: 0;
    font-size: 12px;
    color: #999;
}

.notification-viewed-line {
    position: relative;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    text-align: center;
}

.notification-viewed-line > div {
    position: relative;
    display: inline-block;
    z-index: 1;
    background-color: #f6f6f6;
    padding: 0 1em;
}

.notification-viewed-line:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 11px;
    height: 1px;
    background-color: #999;
    z-index: 0;
    content: '';
}

.notifications-older-link {
    text-align: center;
    font-size: 12px;
}

.subscription-form {
    font-size: 12px;
    line-height: 22px;
}

.subscription-form input {
    margin: 0;
}

.subscription-form label {
    display: inline-block;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
}


/* popup notifications */

.popup-notifications {
    position: fixed;
    top: 3%;
    right: 3%;
    width: 16em;
    max-width: 94%;
    overflow: visible;
    z-index: 15;
}

.popup-notification {
    background-color: rgba(0, 140, 0, 0.9);
    margin-bottom: 1em;
    padding: 0.5em;
    color: white;
    font-size: 0.8em;
    cursor: pointer;
    border-radius: 0.35em;
    box-shadow: 0 0 0.2em rgba(0, 70, 0, 0.8);
    transition: ease 0.3s;
    transition-property: transform, opacity;
    position: relative;
    opacity: 1.0;
}

.popup-notification.notice-hidden {
    transform: translateY(-1em);
    opacity: 0.0;
}

.popup-notification.notice-error {
    background-color: rgba(140, 0, 0, 0.9);
    box-shadow: 0 0 0.2em rgba(70, 0, 0, 0.8);
}


/* admin pages */

.admin-dashboard {
    width: 100%;
    text-align: justify;
    text-align-last: justify;
    font-size: 12px;
    line-height: 16px;
}

.admin-dashboard > a {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 8px);
    min-height: 98px;
    box-sizing: border-box;
    margin: 0 0 16px 0;
    padding: 16px;

    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 2px #d5d5d5;

    text-align: center;
    text-align-last: center;
    text-decoration: none;
    color: inherit;
}

.admin-dashboard > a.short {
    min-height: 0;
}

.admin-dashboard > a:hover {
    background-color: #f8f8f8;
}

.admin-dashboard-label {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.admin-dashboard > a:hover .admin-dashboard-label {
    text-decoration: underline;
}

.admin-dashboard-desc {
    margin-top: 16px;
}

.admin-index {
    /* nothing */
}

.admin-index-table-container {
    width: 75%;
    float: left;
}

.admin-index-table {
    width: 100%;
}

.admin-index-table th {
    background-color: white;
    font-weight: bold;
    text-align: left;
}

.admin-index-table .td-sortable-link {
    display: block;
    color: inherit;
    text-decoration: none;
}

.admin-index-table .td-sortable-link:hover {
    text-decoration: underline;
}

.admin-index-table tbody tr {
    border-bottom: 1px solid #eee;
}

.admin-index-table th, .admin-index-table td {
    border-bottom: 1px solid #eee;
    padding: 4px 2px;
}

.admin-index-table .col-nowrap {
    white-space: nowrap;
}

.admin-index-table th.col-user-id-title,
.admin-index-table td.col-user-id {
    text-align: right;
    padding-right: 4px;
}

.admin-index-table .col-user-avatar a {
    text-decoration: none;
    color: inherit;
}

.admin-index-table .col-user-avatar {
    width: 28px;
    height: 28px;
    line-height: 28px;
}

.admin-index-table td.col-user-name {
    padding: 2px 2px;
}

.admin-index-table td.col-user-name img {
    margin-right: 2px;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    border: 0;
    border-radius: 2px;
}

.admin-index-table .col-user-name a {
    font-weight: bold;
}

.admin-index-table .col-user-active-title,
.admin-index-table .col-user-active {
    text-align: center;
}

.admin-index-table .col-vote-story-title {
    width: 45%;
}

.icon-admin-checkbox-true,
.icon-admin-checkbox-false {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.icon-admin-checkbox-true {
    background-color: #080;
}

.icon-admin-checkbox-false {
    background-color: #a00;
}

.admin-index-filters {
    width: 25%;
    float: right;
    box-sizing: border-box;
    padding-left: 1em;
}

.admin-input-text-full {
    margin-left: 0;
    margin-right: 0;
    width: calc(100% - 12px - 2px);  /* margin and padding */
}

.admin-input-select-full {
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
    width: 100%;
}

.admin-log-list {
    background-color: white;
    padding: 8px;
}

.admin-log-item {
    font-size: 12px;
    border-top: 1px solid #eee;
    margin: 8px 0;
}

.admin-log-item.log-item-addition .log-action-label {
    color: #080;
}

.admin-log-item.log-item-change .log-action-label {
    color: #00a;
}

.admin-log-item.log-item-deletion .log-action-label {
    color: #a00;
}

.admin-log-item .log-item-meta,
.admin-log-item .log-item-meta a {
    color: #999;
}

.admin-items-list > li {
    margin: 4px 0;
}

@media (max-width: 840px) {
    .admin-index-table-container {
        float: none;
        width: 100%;
        overflow-x: scroll;
        padding-bottom: 16px;
    }

    .admin-index-filters {
        float: none;
        width: 100%;
    }
}

@media (max-width: 660px) {
    .admin-index {
        font-size: 10px;
    }
}

/* etc */

.timezone-select {
    width: auto;
}


/* === Adaptive overrides === */

@media (max-width: 1000px) {
    .container {
        margin-bottom: 0;
        border-radius: 0;
    }
    .footer {
        border-radius: 0;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

@media (max-width: 1100px) {
    .input-xxxlarge {
        width: 530px;
    }
}

/* Tablets */
@media ( min-width : 768px) and (max-width: 979px) {
    .container {
        width: auto;
        min-width: 728px;
        padding: 0;
    }
    .nav-main {
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
    .logopic,.page-header {
        box-shadow: none;
    }
    .page-header,.nav-pills,.footer,.logopic {
        margin-left: 0;
        width: auto;
    }
    #content {
        padding: 0 0 0 20px;
    }
    .span12, .staticpage-content {
        width: 96%;
    }
    .span8 {
        width: 62%;
        min-width: 475px;
        max-width: 626px;
    }
    .span4 {
        width: 31%;
        min-width: 242px;
        max-width: 313px;
    }
    .welcome-box .span4,.welcome-box .span8 {
        width: 96%;
        max-width: 96%;
    }
    .main-menu .logolink {
        font-size: 32px;
    }
    .chapter-text-block {
        width: calc(100% - 20px);
        margin: 0;
    }
    .story-panel {
        margin-right: 0;
    }
    .all-tags-list > li {
        width: 50%;
    }
}

@media ( min-width : 768px) {
    .modal {
        left: 45%;
        width: 640px;
    }
    .modal-body {
        max-height: 450px!important;
    }
    .modal textarea.span4 {
        max-width: 85%;
    }
}

/* Tablets portrait */
@media ( max-width : 767px) {
    .container {
        width: auto;
        min-width: 480px;
        padding: 0;
    }
    .nav-main {
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
    .logopic,.page-header {
        box-shadow: none;
    }
    .page-header,.nav-pills,.footer {
        margin-left: 0;
        width: auto;
    }
    #content {
        padding: 0 0 0 20px;
    }
    .span12,.span8,.span4 {
        width: 99%;
    }
    .logopic {
        display: none
    }
    .main-menu .logolink {
        font-size: 26px;
    }
    .main-menu li {
        font-size: 17px;
        padding-right: 14px;
    }
    .nav-pills {
        font-size: 12px;
        padding: 10px 12px 10px;
    }
    .nav-pills>li>a {
        padding: 6px 8px;
    }
    .story-panel {
        margin-right: -1%;
    }
    .story-panel.story-panel-floating {
        opacity: 1;
    }
    .chapter-text-block {
        width: calc(100% - 20px);
        margin: 0;
    }
    .controls textarea {
        max-width: 400px;
        max-height: 200px;
    }
    .search-input {
        width: 256px;
    }
    .story-chapter-page .story-panel {
        height: auto;
    }
    .comments-tree .comment-item {
        max-width: 100%;
    }
    .comment-form-content {
        width: auto;
    }
    .comment-add-link {
        font-size: 16px;
    }
    #comment-add-link + .comment-form {
        background-image: none;
        min-height: auto;
    }
    .input-xxxlarge {
        width: calc(100% - 12px - 12px);
    }
    .admin-dashboard > a {
        display: block;
        width: auto;
        min-height: 0;
        margin-right: calc(12px - 1%);
    }
    .admin-log-list {
        margin-right: calc(12px - 1%);
    }
    .all-tags-list > li {
        width: 50%;
    }
}

/* Smartphones */
@media ( max-width : 480px) {
    .container {
        min-width: 240px;
    }
    #content {
        padding: 0 0 0 12px;
    }
    .page-header {
        height: 68px;
        padding-top: 12px;
    }
    .controls textarea {
        width: calc(100% - 12px - 12px);
    }
    .search-input {
        width: calc(100% - 12px - 2px);
        margin-right: 0;
    }
    .main-menu .logolink {
        font-size: 15px;
        padding-right: 6px;
    }
    .main-menu li {
        font-size: 14px;
        padding-right: 4px;
    }
    .nav-pills {
        font-size: 14px;
        padding: 4px 12px 8px;
    }
    .nav-pills>li>a {
        padding: 6px 8px;
    }
    .nav-tabs>li,.nav-pills>li {
        padding: 0 0 0;
    }
    h1 {
        font-size: 22px;
        line-height: 28px;
    }
    .story-info-header {
        font-size: 22px;
        line-height: 28px;
    }
    .chapter-text-block {
        width: calc(100% - 12px);
        margin: 0;
    }
    .chapter-text {
        font-size: 12px;
        line-height: 22px;
    }
    .characters-group {
        display: inline;
        padding-right: 0;
    }
    /*
    .padded>.bootstrap.radio {
        display: none;
    }
    */
    .all-tags-list > li {
        width: 100%;
    }
}

/* 320 px screens */
@media ( max-width : 320px) {
    .nav-pills>li>a {
        padding: 4px;
        font-size: 13px;
    }
    .story-tag {
        font-size: 12px;
        line-height: 14px;
        padding: 4px 5px;
    }
    .login-form .input-small {
        width: 56px;
    }
}
