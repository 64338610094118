:root {
    --max-lines: 4;
    --slides-height: 13rem;
    --slides-border: 1rem;
    --bullets-margin: 0.5rem;
    --bullets-size: 0.75em;
    --bullets-color-main: #999;
    --bullets-color-accent: #555;
    --formatting-color: #555;

    --color-red: #DC6F65;
    --color-yellow: #DD8830;
    --color-green: #729D34;
    --color-blue: #668FF1;
    --color-violet: #9B82E6;
}
