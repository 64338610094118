:root {
  --background: #fff;
  --default-tag-color: #999;
  --highlight-filters: saturate(104%) brightness(104%);
}

@define-mixin tag-box {
  min-height: 2.3em;
  max-height: 15em;
  overflow-y: auto;
  background-color: var(--background);
  border: 1px solid #ccc;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tags-container {
  @mixin tag-box;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  padding: 0.3em 0.5em;

  width: 80%;

  transition: border linear 0.2s, box-shadow linear 0.2s;
}

.react-tagsinput--focused > .tags-container {
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, .6);
}

.tags-input-container {
  display: none!important;
}

.tag-block {
  margin: 0.15em;
  padding: 0.5em;
}

.tag-item {
  background-color: var(--default-tag-color);
  border-radius: 0.25em;
  font-weight: bold;
  line-height: 1em;
  color: var(--background);
  white-space: nowrap;
  width: fit-content;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  & span {
    &::before {
      content: " ×";
    }
  }
  &:hover {
    filter: var(--highlight-filters);
  }
}

.tag-item-type-default {
  background-color: var(--default-tag-color);
}

.tag-remove {
  cursor: pointer;
  font-weight: bold;
}

.tag-help {
  font-size: smaller;
  font-style: italic;
  margin: 0.25em 0.5em;
}

.react-autosuggest__container {
  flex-grow: 1;

  & .dropdown-input {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 28px;
    box-sizing: border-box;

    /* disable default bootstrap styles */
    border: 0;
    box-shadow: none;
    background-color: transparent;

    &:focus {
      box-shadow: none;
    }
  }
}

.react-autosuggest__suggestions-container {
  @mixin tag-box;
  margin: 0.15em;
  position: absolute;
  z-index: 1000;
  visibility: hidden;
  max-width: 30em;
}

.react-autosuggest__suggestions-container--open {
  visibility: visible;
}

.react-autosuggest__suggestions-list {
  padding: 0.3em;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
}

.react-autosuggest__suggestion {
  cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
  filter: var(--highlight-filters);
}
